import React, { useEffect } from 'react';

import { isMobile, isMobileOnly } from 'react-device-detect';
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import LazyLoad from 'react-lazy-load';
import homeMobile from '../../../Assets/images/home/homemobile.webp';
import homeDesktop from '../../../Assets/images/home/home.webp';

const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true)

  //   useEffect(()=>{
  //  //-----------------------------------Banner
  //  setLoading(true)
  //  axios.get(base_url + `/home/slider`, {
  //   headers: postHeader
  // })
  //   .then(response => {
  //     if (response.data.status == 1) {
  //       setLoading(false)
  //       setBanner(response.data.data);
  //       console.log('banner', response.data)
  //       localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
  //     } else {
  //       toast.error(response.message, { autoClose: 3000 });
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
  //   },[])

  return (
    <div className="rcs_hero_slider position-relative" style={{ backgroundImage: `url(${isMobileOnly ? homeMobile : homeDesktop})` }}>
      <div className="rcs_hero_img">
        <Container fluid>
          <div className={`homeBanner_slider ${isMobile ? 'text-center' : "text-left"} text-center`}>
            <h1> Jewelry For Every Occasion</h1>
            <p >We are a trusted jewelry store in Chicago, Illinois, offering a wide range of fine jewelry pieces.</p>
            <Button onClick={() => { history.push('/engagement-rings') }} className="outline-base-btn large">Shop Engagement Rings</Button>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default BannerSlider;