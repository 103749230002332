import React from 'react'
import RingBuilderImg from "./assets/images/repair_img.webp"
import Mobile from "./assets/images/repair_img_mobile.webp"
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Container from './Container';

const RepairSection = () => {
  return (
    <>
      <Image src={Mobile} alt='' className='pb-0 d-md-none d-block' />
      <div className='repair-section' style={{background: `url(${RingBuilderImg}) no-repeat center top/cover`}}>
        <Container>
            <Row>
                <Col lg={5} md={7} className='text-md-start text-center'>
                    <h6 className='pb-2 m-0'>Jewelry & Watch Repair Services</h6>
                    <p className='mt-2 mb-4'>
                    European Jewelry is a trusted name for watch and jewelry repair in Chicago. 
                    We service all major luxury watch brands, and our team of experienced professionals 
                    is dedicated to restoring your valuable pieces with the highest level of care.
                     Contact us to find out how we can help restore your treasures.
                       </p>

                    <Link to = "/services" className="btn outline-base-btn">Explore Now</Link>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default RepairSection