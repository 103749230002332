import React from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import Img from "./assets/images/showroom.webp";
import { Link } from 'react-router-dom/cjs/react-router-dom';

const VisitShowroom = () => {
  return (
    <section className='rcs_visit_showroom'>
      <div className='inner-wrap'>
        <div className='image'>
            <Image src={Img} alt='visit our showroom' />
        </div>

        <div className='content'>
            <h5 className='mb-3'>Visit Our Store</h5>
            <p className='mb-4'> 
            Visit our showroom to explore a wide selection of jewelry, from sparkling diamond engagement rings and beautifully crafted fine pieces to gemstones in Chicago. Whether you're looking for the perfect ring or a special treat for yourself, we have something for every occasion. Our skilled artisans also offer expert jewelry repair services to bring your treasured pieces back to life. Stop by European Jewelers today and discover true luxury!

                </p>
            <Link to="/make-an-appointment" className="btn outline-base-btn">Book an Appointment</Link>
        </div>
      </div>
    </section>
  )
}

export default VisitShowroom